<script setup>
import { ref, onMounted } from 'vue';

defineExpose({ executeCommand, addEventListener, removeEventListener, embedJitsiWidget, removeJitsiWidget });

const props = defineProps({
  domain: {
    type: String,
    default: 'meet.jit.si',
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});

const jitsiApi = ref(null);
const jitsiMountPoint = ref(null);

onMounted(() => {
  embedJitsiWidget();
});

function embedJitsiWidget () {
  if (jitsiApi.value) return;
  const options = {
    ...props.options,
    parentNode: jitsiMountPoint.value,
  };
  jitsiApi.value = new window.JitsiMeetExternalAPI(props.domain, options);
};

function executeCommand (command, ...value) {
  jitsiApi.value.executeCommand(command, ...value);
}

function addEventListener (event, fn) {
  jitsiApi.value.addListener(event, fn);
}

function removeEventListener (event, fn) {
  jitsiApi.value.removeListener(event, fn);
}

// Misc
function removeJitsiWidget () {
  if (jitsiApi.value) jitsiApi.value.dispose();
}
</script>

<template>
  <!-- TODO loading -->
  <!-- TODO invalid/finished/expired session ui -->
  <div ref="jitsiMountPoint" id="jitsi-mount-point" style="height: 100%; width: 100%;"></div>
</template>